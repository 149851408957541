/* Reusable DataCard, GroundAndFreight, LineChart */
.dataStyle {
  background-color: #2a2f33;
  margin-bottom: 5%;
  text-align: left;
  padding: 13px;
}

/*Reusable ChartCard, EndingCard */
.endingStyle {
  background-color: #2a2f33;
  margin-bottom: 5%;
  padding: 13px;
}
.endingStyle p {
  font-size: 14px;
  margin-bottom: 0;
}
.endingCard {
  background-color: #2a2f33;
  margin-bottom: 5%;
  /* padding: 13px; */
}

/* AnnouncmentCarrd */
.announceStyle {
  background-color: #2a2f33;
  margin-bottom: 5%;
  text-align: left;
  min-height: 20vh;
  padding: 13px;
}
/* GroundandFreightCard */
.leftHeader {
  text-align: left;
}
.leftData {
  text-align: left;
  padding-right: 20px;
  border-right: 2px solid rgba(255, 255, 255, 0.6);
}
.rightHeader {
  text-align: left;
}
.rightData {
  text-align: left;
}
/* DataCard */
.leftHeading {
  text-align: left;
  margin-bottom: 10px;
}
.new-tble-bx th {
  color: #27b107;
  font-size: 20px;
  font-weight: 600;
  background: var(--bs-table-striped-bg);
}
.new-tble-bx th {
  border: 2px solid #000;
  border-top: none;
}
.new-tble-bx tr th:nth-of-type(6) {
  border-right: none;
}
.new-tble-bx tr th:first-child {
  border-left: none;
}

.new-tble-bx td {
  border: 2px solid #000;
  border-top: none;
  border-bottom: none;
  /* color: #c2c2c2 !important; */
}
.new-tble-bx tr td:nth-of-type(6) {
  border-right: none;
}
.new-tble-bx tr td:first-child {
  border-left: none;
}

#root .container-fluid {
  position: relative;
}
#root .container-fluid .fa-solid.fa-arrow-left {
  position: absolute;
  /* left: 10%; */
  left: 1%;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  #root .container-fluid .fa-solid.fa-arrow-left {
    left: 17px;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .app-first-page {
    height: auto;
  }
}
