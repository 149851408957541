.datepicker-custom-bx {
  background-color: #505458;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  padding: 8px;
}
.datepicker-custom-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cstm-date-btn1 {
  color: #23b7ca;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
}
.cstm-date-btn1:disabled {
  color: #7e7e7e;
}
.cstm-date-select {
  background: none;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 12px;
  width: 64px;
  outline: none;
  margin: 0 3px;
}
.cstm-select-start {
  display: flex;
  position: relative;
}
.cstm-date-select {
  position: relative;
}
.cstm-date-select:last-child {
  border-left: 1px solid #c0c0c0 !important;
  width: 77px;
  padding-left: 8px;
  margin-left: 6px;
}
.cstm-date-select option {
  color: #000;
}

.cstm-date-btn2 {
  color: #23b7ca;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}
.react-datepicker__header {
  padding: 0px !important;
  border-bottom: none !important;
  border-top-right-radius: 0.8rem !important;
  border-top-left-radius: 0.8rem !important;
  background-color: #505458 !important;
}
.react-datepicker {
  border-top-right-radius: 0.8rem !important;
  border-top-left-radius: 0.8rem !important;
  border: none !important;
  background-color: #424548 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-top: none !important;
  border-bottom-color: #505458 !important;
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  left: 0 !important;
  right: 0;
  margin: 0 auto;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff !important;
}

.cstm-date-select option {
  background-color: #424548 !important;
  color: #fff;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #216ba5 !important;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: #fff0 !important;
}
.cstm-date-select::-webkit-scrollbar {
  width: 10px;
}

.cstm-date-select::-webkit-scrollbar-track {
  background-color: #22292f;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.cstm-date-select::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: #0ec0d7;
}
.inner-hotel-book-form-bx .form-group {
  position: relative;
}
.date-calender-icon {
  background: #fff0;
  /* border: none; */
  position: absolute;
  right: 13px;
  bottom: 11px;
}
.date-calender-icon i {
  width: 20px;
  color: #23b7ca;
}

.timeOnly .react-datepicker-popper[data-placement^="top"] {
  width: 100% !important;
}
.timeOnly .react-datepicker__time-container {
  float: none !important;
  width: 100% !important;
}
.timeOnly .react-datepicker {
  width: 100% !important;
}
.timeOnly .react-datepicker-time__header {
  padding: 10px;
  color: #fff;
}
.timeOnly
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
}
.timeOnly .react-datepicker-popper[data-placement^="bottom"] {
  width: 100%;
}
.time-stripes {
  color: #fff;
  background: #424548;
  text-align: left;
  padding-left: 30px !important;
  position: relative;
  border-bottom: 1px solid hsla(0, 0%, 80%, 0.15);
}
.time-stripes::after {
  position: absolute;
  content: " ";
  width: 5px;
  height: 5px;
  background: #fff;
  top: 12px;
  left: 13px;
}
.time-stripes:hover {
  background-color: #216ba5 !important;
  color: #fff;
}

.timeOnly
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 10px;
}

.timeOnly
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-track {
  background-color: #22292f;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.timeOnly
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: #0ec0d7;
}
