.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #21262a;
  min-height: 100vh;
  max-height: auto;
  color: white;
}
.secondHeader {
  color: #e3c25d;
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
}
.header {
  color: #588b4c;
}

.header1 {
  color: aliceblue;
}
/* .green/{ */
.green {
  color: #aed3ac;
}
.red {
  color: #ff5156;
}
.app-first-page {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-first-page .row {
  align-items: center;
  width: 100%;
}
.app-first-page .card-body a {
  color: #fff;
  text-decoration: none;
}
.App .apexcharts-datalabel-value {
  color: #fff !important;
}
.apexcharts-canvas {
  margin: 0 auto;
}

.new-frm-box {
  margin: 0 auto;
  padding: 50px;
  border-radius: 6px;
  background: #2a2f33;
  width: 40%;
}
.new-frm-sec-start {
  padding-top: 30px;
}
.new-frm-sec-start h1 {
  font-size: 30px;
  padding-bottom: 20px;
}

.new-frm-sec-start .form-group {
  text-align: left;
  margin-top: 20px;
}
.new-frm-sec-start .form-group .form-control {
  background: #e0e0e0;
  height: 50px;
  transition: 0.5s;
  outline: none;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  padding-left: 15px;
  position: relative;
  /* height: 50px; */
  margin-top: 9px;
}
.all-frm-box {
  position: relative;
}
.all-frm-box i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #09a800;
  pointer-events: none;
}
.all-frm-box input[type="date"]::-webkit-inner-spin-button,
.all-frm-box input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.btn-new-btn {
  padding: 10px 30px;
  background: 09a800;
}
.downloadreport_btn {
  background: #7fd320;
  border: none;
  margin-top: 30px;
  color: #000;
  font-weight: 600;
  border-radius: 6;
  min-width: 200px;
}
.new-frm-box .form-group label {
  font-weight: 600;
}

.new-frm-sec-start .form-group .frm_box_wrap .form-control {
  background: #e0e0e024;
  height: 50px;
  transition: 0.5s;
  outline: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  padding-left: 15px;
  position: relative;
  /* height: 50px; */
  margin-top: 9px;
}
.frm_box_wrap i {
  position: absolute;
  right: 15px;
  top: 54%;
  transform: translateY(-40%);
  font-size: 18px;
  color: #09a800;
  pointer-events: none;
}
.frm_box_wrap .form-control option {
  color: #fff !important;
  font-size: 16px;
  background: #505458 !important;
  font-weight: 400;
  border-radius: 2px !important;
}
/* .frm_box_wrap .form-control option:hover {
  background: #505458 !important;
  color: #000 !important;
} */
/* .frm_box_wrap .form-control option:focus {
  background: #fff !important;
  color: #000 !important;
} */
.frm_box_wrap .react-datepicker-wrapper {
  display: flex;
}
.frm_box_wrap .form-control:focus {
  outline: none;
  box-shadow: none;
}

@media only screen and (max-width: 1180px) {
  .new-frm-box {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .new-frm-box {
    width: 70%;
  }
}
@media only screen and (max-width: 991px) {
  .new-frm-box {
    margin: 0 auto;
    padding: 40px;
    border-radius: 6px;
    background: #2a2f33;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .downloadreport_btn {
    width: 100%;
  }
}
